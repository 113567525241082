import {
  FETCH_GAMESLIST_BEGIN,
  FETCH_GAMESLIST_FAIL,
  FETCH_GAMESLIST_SUCCESS,
  ADD_GAME,
} from './constants'

export const initialState = {
  gamelog: { fetching: 0, data: [] },
  bigwins: { fetching: 0, data: [] },
  rarewins: { fetching: 0, data: [] },
  recentwins: { fetching: 0, data: [] },
}

const fetchGameslistSuccess = (state, payload) => {
  const { listname, data } = payload
  return { ...state, [listname]: { data, fetching: 0 } }
}

const fetchGameslistFail = (state, payload) => {
  const { listname } = payload
  return { ...state, [listname]: { ...state[listname].data, fetching: 2 } }
}

const fetchGameslistBegin = (state, payload) => {
  const { listname } = payload
  return { ...state, [listname]: { data: state[listname].data, fetching: 1 } }
}

const addGameToWinlist = (state, payload) => {
  const { listname, data } = payload
  const newList = [data, ...state[listname].data]
  newList.pop()
  return { ...state, [listname]: { ...state[listname], data: newList } }
}

const gameslist = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GAMESLIST_BEGIN:
      return fetchGameslistBegin(state, action.payload)
    case FETCH_GAMESLIST_FAIL:
      return fetchGameslistFail(state, action.payload)
    case FETCH_GAMESLIST_SUCCESS:
      return fetchGameslistSuccess(state, action.payload)
    case ADD_GAME:
      return addGameToWinlist(state, action.payload)
    default:
      return state
  }
}

export default gameslist
