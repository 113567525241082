import {
  SET_SELECTED_ADDRESS,
  SET_SELECTED_VALUE,
  TOGGLE_SOUND_MUSIC,
  TOGGLE_SOUND_SFX,
  ADD_TRANSACTION_ID,
} from './constants'

export const initialState = {
  selectedAddress: '',
  selectedValue: 0,
  soundMusic: false,
  soundSFX: false,
  txids: [],
}

const setSelectedAddress = (state, selectedAddress) => {
  return { ...state, selectedAddress }
}

const setSelectedValue = (state, selectedValue) => {
  return { ...state, selectedValue }
}

const toggleSoundMusic = state => {
  return {
    ...state,
    soundMusic: !state.soundMusic,
  }
}

const toggleSoundSFX = state => {
  return {
    ...state,
    soundSFX: !state.soundSFX,
  }
}

const addTransactionId = (state, txid) => {
  const { txids } = state
  if (typeof txids === 'undefined') return { ...state, txids: [txid] }
  const txlist = txids.length < 50 ? txids : txids.pop()
  return {
    ...state,
    txids: [...txlist, txid],
  }
}

const meta = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_ADDRESS:
      return setSelectedAddress(state, action.payload)
    case SET_SELECTED_VALUE:
      return setSelectedValue(state, action.payload)
    case TOGGLE_SOUND_MUSIC:
      return toggleSoundMusic(state)
    case TOGGLE_SOUND_SFX:
      return toggleSoundSFX(state)
    case ADD_TRANSACTION_ID:
      return addTransactionId(state, action.payload)
    default:
      return state
  }
}

export default meta
