// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/builder/SD2018/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fair-js": () => import("/home/builder/SD2018/src/pages/fair.js" /* webpackChunkName: "component---src-pages-fair-js" */),
  "component---src-pages-index-js": () => import("/home/builder/SD2018/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rules-js": () => import("/home/builder/SD2018/src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */),
  "component---src-pages-stack-history-js": () => import("/home/builder/SD2018/src/pages/stack/history.js" /* webpackChunkName: "component---src-pages-stack-history-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/builder/SD2018/.cache/data.json")

