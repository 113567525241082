import {
  FETCH_GAMERULES_BEGIN,
  FETCH_GAMERULES_FAIL,
  FETCH_GAMERULES_SUCCESS,
  INCREMENT_COUNTER,
} from './constants'

import BITBOXSDK from 'bitbox-sdk'

export const initialState = { fetching: 0 }

const BITBOX = new BITBOXSDK()

const fetchGamerulesSuccess = (state, payload) => {
  const { addresses, addresslist } = payload
  const updatedAddresses = addresslist.reduce((prev, curr) => {
    const addressData = {
      ...addresses[curr],
      cashaddr: BITBOX.Address.toCashAddress(curr),
      address: curr,
    }
    return { ...prev, [curr]: addressData }
  }, {})

  const orderedOdds = Object.keys(updatedAddresses).reduce((prev, curr) => {
    updatedAddresses[curr]['address'] = curr
    return [...prev, updatedAddresses[curr]]
  }, [])

  return {
    ...state,
    addresses: { ...updatedAddresses },
    orderedOdds,
    fetching: 0,
    country: payload.country,
    gamescount: payload.gamescount,
    playerreturn: payload.playerreturn,
  }
}

const fetchGamerulesBegin = state => {
  return { ...state, fetching: 1 }
}

const fetchGamerulesFail = state => {
  return { ...state, fetching: 2 }
}

const incrementCounter = state => {
  return { ...state, gamescount: state.gamescount + 1 }
}

const gamerules = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GAMERULES_BEGIN:
      return fetchGamerulesBegin(state, action.payload)
    case FETCH_GAMERULES_FAIL:
      return fetchGamerulesFail(state)
    case FETCH_GAMERULES_SUCCESS:
      return fetchGamerulesSuccess(state, action.payload)
    case INCREMENT_COUNTER:
      return incrementCounter(state)
    default:
      return state
  }
}

export default gamerules
