import {
  FETCH_PRICES_BEGIN,
  FETCH_PRICES_FAIL,
  FETCH_PRICES_SUCCESS,
} from './constants'

export const initialState = { fetching: 0 }

const fetchPricesSuccess = (state, payload) => {
  return { ...state, ...payload, fetching: 0 }
}

const fetchPricesFail = state => {
  return { ...state, fetching: 2 }
}

const fetchPricesBegin = state => {
  return { ...state, fetching: 1 }
}

const prices = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRICES_BEGIN:
      return fetchPricesBegin(state)
    case FETCH_PRICES_FAIL:
      return fetchPricesFail(state)
    case FETCH_PRICES_SUCCESS:
      return fetchPricesSuccess(state, action.payload)
    default:
      return state
  }
}

export default prices
