import {
  FETCH_FAIR_GAMES_BEGIN,
  FETCH_FAIR_GAMES_FAIL,
  FETCH_FAIR_GAMES_SUCCESS,
  FETCH_VERIFIED_GAME_BEGIN,
  FETCH_VERIFIED_GAME_FAIL,
  FETCH_VERIFIED_GAME_SUCCESS,
} from './constants'

export const initialState = {
  fairgames: { fetching: 0 },
  verified: { fetching: 0 },
}

const fetchFairgamesSuccess = (state, payload) => ({
  ...state,
  fairgames: { data: payload, fetching: 0 },
})

const fetchFairgamesBegin = state => ({
  ...state,
  fairgames: { ...state.fairgames, fetching: 1 },
})
const fetchFairgamesFail = state => ({
  ...state,
  fairgames: { ...state.fairgames, fetching: 2 },
})

const fetchVerifiedGameBegin = state => ({
  ...state,
  verified: { ...state.verified, fetching: 1 },
})
const fetchVerifiedGameFil = state => ({
  ...state,
  verified: { ...state.verified, fetching: 2 },
})

const fetchVerifiedGameSuccess = (state, payload) => ({
  ...state,
  verified: { data: payload, fetching: 0 },
})

const fairgames = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FAIR_GAMES_BEGIN:
      return fetchFairgamesBegin(state)
    case FETCH_FAIR_GAMES_FAIL:
      return fetchFairgamesFail(state)
    case FETCH_FAIR_GAMES_SUCCESS:
      return fetchFairgamesSuccess(state, action.payload)
    case FETCH_VERIFIED_GAME_BEGIN:
      return fetchVerifiedGameBegin(state)
    case FETCH_VERIFIED_GAME_FAIL:
      return fetchVerifiedGameFil(state)
    case FETCH_VERIFIED_GAME_SUCCESS:
      return fetchVerifiedGameSuccess(state, action.payload)
    default:
      return state
  }
}

export default fairgames
